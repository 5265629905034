import React from "react"
import { compose } from "ramda"
import { getImageEdges, getImageQueryFluidData } from "@utils/utils"
import { useStaticQuery, graphql } from "gatsby"

import ImageGallery from "@components/shared/ImageGallery/ImageGallery"

const OfficeGallery = React.memo(() => {
  const data = useStaticQuery(graphql`
    query officeGallery {
      allImageSharp(
        filter: { fluid: { originalName: { glob: "office*" } } }
        sort: { fields: resize___originalName }
      ) {
        edges {
          node {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_tracedSVG
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  `)

  const imageList = compose(getImageQueryFluidData, getImageEdges)(data)

  const columns = {
    queries: [
      "(max-width: 576px)",
      "(max-width: 768px)",
      "(max-width: 992px)",
      "(max-width: 1200px)",
      "(min-width: 1201px)",
    ],
    values: [1, 1, 2, 3, 3],
    defaultValue: 2,
  }

  return <ImageGallery list={imageList} {...columns} />
})

export default OfficeGallery
