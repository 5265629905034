/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import "./BenefitsSection.scss"
import React, { useState } from "react"
import CopySection from "@components/shared/CopySection/CopySection"
import { Container } from "reactstrap"
import { map, prop, addIndex } from "ramda"
import { useSwipeable } from "react-swipeable"

export default ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const benefitsBlockList = prop("blocks", data)

  const labelList = addIndex(map)((method, index) => {
    const label = prop("label", method)

    return (
      <li
        key={index}
        className={`benefits-section-label-list-item ${
          index === activeIndex ? "benefits-section-label-list-item-active" : ""
        }`}
      >
        <button className="btn btn-link" onClick={() => setActiveIndex(index)}>
          {label}
        </button>
      </li>
    )
  }, benefitsBlockList)

  const next = () => {
    const nextIndex = activeIndex === labelList.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    const nextIndex = activeIndex === 0 ? labelList.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: previous,
    delta: 10,
    preventDefaultTouchmoveEvent: true,
  })

  const indicatorsList = labelList.map((_, index) => (
    <li
      key={index}
      onClick={() => setActiveIndex(index)}
      className={index === activeIndex ? "active" : null}
    ></li>
  ))

  const excerptList = addIndex(map)((method, index) => {
    const excerpt = prop("text", method)

    return (
      <li
        key={index}
        className={`benefits-section-excerpt-list-item ${
          index === activeIndex
            ? "benefits-section-excerpt-list-item-active"
            : ""
        }`}
      >
        <p>{excerpt}</p>
      </li>
    )
  }, benefitsBlockList)

  return (
    <CopySection
      anchor={"benefits"}
      className="pt-md-0 bg-lighter"
      data={data}
      dotColor="green"
    >
      <>
        <Container className="p-0">
          <div className="benefits-section-grid" {...swipeHandlers}>
            <div className="benefits-section-flex-item-1">
              <ul className="benefits-section-label-list">{labelList}</ul>
            </div>
            <div className="benefits-section-flex-item-2 pl-md-5">
              <ul className="benefits-section-excerpt-list">{excerptList}</ul>
            </div>
          </div>
          <ol className="d-md-none carousel-indicators">{indicatorsList}</ol>
        </Container>
      </>
    </CopySection>
  )
}
