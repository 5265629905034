import React, { Fragment, useEffect, useState } from "react"
import Link from "@components/shared/Link/Link"
import PositionListItem from "@components/shared/Positions/PositionListItem/PositionListItem"
import {ContentService} from "../../../../utils/content-service"
import {take} from 'rxjs/operators'


const PositionList = props => {
  const [positions, setPositions] = useState(null)

  useEffect(() => {
    ContentService.instance
      .getPositions()
      .pipe(take(1))
      .subscribe()
    const sub = ContentService.instance.positions.subscribe(positions => {
      // sort initiative position to the last position
      const initiative = positions.find(a => a.id === "221820")
      const positionsTemp = positions.filter(a => a.id !== "221820")
      if (initiative) {
        positionsTemp.push(initiative)
      }

      if (props.limit) {
        const slicedPositions = positionsTemp.slice(0, props.limit)
        setPositions(slicedPositions)
      } else {
        setPositions(positionsTemp)
      }
    })

    return () => {
      sub.unsubscribe();
    }
  }, [props.limit])

  return (
    <Fragment>
      {positions ? (
        <div className="d-flex flex-column flex-grow-1 w-100">
          {positions?.map(params => {
            return <PositionListItem key={params.id} {...params} />
          })}
        </div>
      ) : null}
      {props.limit ? (
        <div className="py-4 pb-md-0">
          <Link
            className="btn btn-sm btn-light border border-dark float-right"
            to="/careers#open-positions"
          >
            Alle Stellenangebote
          </Link>
        </div>
      ) : null}
    </Fragment>
  )
}

export default PositionList
