import "./PositionListItem.scss"
import React from "react"
import Link from "@components/shared/Link/Link"

import imageArrowRight from "@assets/icons/ic_arrow-right.svg"

const PositionListItem = ({ id, name }) => (
  <div className="list-group mb-2">
    <Link
      to={`/position-details/${id}`}
      className="position-link btn btn-block btn-light"
    >
      <h5 className="font-weight-normal m-0">{name}</h5>
      <img alt={name} src={imageArrowRight} className="ml-auto" width="15" />
    </Link>
  </div>
)

export default PositionListItem
