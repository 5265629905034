import React from "react"

import CopySection from "@components/shared/CopySection/CopySection"
import TeamGallery from "@components/careers/TeamGallery/TeamGallery"

const TeamSection = ({ data }) => {
  return (
    <CopySection
      anchor={"team"}
      data={data}
      className="bg-white"
      dotColor="blue"
    >
      <>
        <TeamGallery />
      </>
    </CopySection>
  )
}

export default TeamSection
