import React from "react"
import { Container } from "reactstrap"
import Link from "@components/shared/Link/Link"
import classnames from "classnames"

export default ({
  anchor,
  className,
  data: { title, subtitle, cta, ctaUrl },
  dotColor = "blue",
  showCta = false,
  children,
}) => {
  return (
    <section
      id={anchor}
      className={classnames("py-3 py-sm-4 py-md-5", className)}
    >
      <Container className="py-3 py-sm-4 py-md-5">
        <div className="flex-column align-items-center text-center py-3 py-sm-4 py-md-5">
          <div className="mb-3 mb-sm-4 mb-md-5">
            <h3 className={`m-0 dot dot-${dotColor}`}>{title}</h3>
            <p className="my-4 my-sm-5">{subtitle}</p>
            {showCta ? (
              <Link className="btn btn-sm btn-dark" to={ctaUrl}>
                {cta}
              </Link>
            ) : null}
          </div>
          {children}
        </div>
      </Container>
    </section>
  )
}
