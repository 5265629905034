import React from "react"
import CopySection from "@components/shared/CopySection/CopySection"
import OfficeGallery from "@components/careers/OfficeGallery/OfficeGallery"

const OfficeSection = ({ data }) => {
  return (
    <CopySection
      anchor={"office"}
      data={data}
      className="bg-white"
      dotColor="blue-dark"
    >
      <>
        <OfficeGallery />
      </>
    </CopySection>
  )
}

export default OfficeSection
