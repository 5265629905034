import React from "react"
import {
  compose,
  findIndex,
  head,
  identity,
  last,
  map,
  prop,
  take,
} from "ramda"
import {
  getEdgesForQuery,
  getImageQueryFluidData,
  getQueryData,
  shuffle,
} from "@utils/utils"
import { useStaticQuery, graphql } from "gatsby"
import { useMedia } from "use-media"

import ImageGallery from "@components/shared/ImageGallery/ImageGallery"

const TeamGallery = React.memo(() => {
  const data = useStaticQuery(graphql`
    query teamGallery {
      photos: allImageSharp(
        filter: { fluid: { originalName: { glob: "team*" } } }
      ) {
        edges {
          node {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_tracedSVG
              presentationHeight
              presentationWidth
              originalName
            }
          }
        }
      }
      metadata: allMarkdownRemark(
        filter: {
          frontmatter: {
            path: { eq: "/careers" }
            section: { eq: "careers-photos-team" }
            published: { eq: true }
            lang: { eq: "de" }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              section
              team {
                originalName
                label
                excerpt
              }
            }
          }
        }
      }
    }
  `)

  const metadata = compose(getQueryData, getEdgesForQuery("metadata"))(data)
  const photosMetadata = compose(prop("team"), head)(metadata)

  const defaultQueries = [
    "(max-width: 768px)",
    "(max-width: 992px)",
    "(min-width: 993px)",
  ]
  const defaultValues = [3, 6, 8]

  // Hook1: Tie media queries to the number of images to display
  const displayAmount =
    compose(
      index => defaultValues[index],
      findIndex(identity),
      map(useMedia)
    )(defaultQueries) || last(defaultValues)

  const imageList = compose(
    take(displayAmount),
    shuffle,
    getImageQueryFluidData,
    getEdgesForQuery("photos")
  )(data)

  const columns = {
    queries: [
      "(max-width: 576px)",
      "(max-width: 768px)",
      "(max-width: 992px)",
      "(min-width: 993px)",
    ],
    values: [1, 1, 2, 2],
    defaultValue: 2,
  }

  return (
    <ImageGallery
      className="team mb-5"
      showHoverInfo
      metadata={photosMetadata}
      list={imageList}
      {...columns}
    />
  )
})

export default TeamGallery
