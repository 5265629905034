import React from "react"
import { graphql } from "gatsby"
import { compose } from "ramda"
import { getEdgesForQuery, getQueryData, getSectionDataFor } from "@utils/utils"

/**
 * import Sections
 */
import MainLayout from "@layouts/MainLayout/MainLayout"
import SEO from "@components/shared/SEO/SEO"
import CopySection from "@components/shared/CopySection/CopySection"
import HeaderSection from "@components/shared/HeaderSection/HeaderSection"
import CareersSection from "@components/shared/CareersSection/CareersSection"
import ContactSection from "@components/shared/ContactSection/ContactSection"
import PositionList from "@components/shared/Positions/PositionList/PositionList"
import TeamSection from "@components/careers/TeamSection/TeamSection"
import OfficeSection from "@components/careers/OfficeSection/OfficeSection"
import BenefitsSection from "@components/careers/BenefitsSection/BenefitsSection"

import imageCareers from "@assets/pictures/buehne_jobs.svg"

/**
 * quering data
 */
export const query = graphql`
  query CareersSectionIndexQuery {
    content: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/careers" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            title
            section
            blocks {
              label
              text
            }
          }
          internal {
            content
          }
        }
      }
    }
    footer: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/ui" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            section
            title
            subtitle
            cta
            ctaUrl
          }
          internal {
            content
          }
          html
        }
      }
    }
  }
`

const CareersPage = ({ data }) => {
  const content = compose(getQueryData, getEdgesForQuery("content"))(data)
  const headerSectionData = getSectionDataFor("careers-section-header", content)

  const overviewSectionData = getSectionDataFor(
    "careers-section-overview",
    content
  )

  const officeSectionData = getSectionDataFor("careers-section-office", content)
  const openPositionsSectionData = getSectionDataFor(
    "careers-section-open-positions",
    content
  )

  const benefitsSectionData = getSectionDataFor(
    "careers-section-benefits",
    content
  )

  const teamSectionData = getSectionDataFor("careers-section-team", content)

  const footer = compose(getQueryData, getEdgesForQuery("footer"))(data)
  const contactSectionData = getSectionDataFor(
    "ui-section-footer-careers",
    footer
  )

  return (
    <MainLayout>
      <SEO />
      <HeaderSection
        anchor={"overview"}
        data={headerSectionData}
        image={imageCareers}
      />
      <CopySection
        anchor={"overview"}
        data={overviewSectionData}
        className="bg-blue-lighter"
      />
      <div id="section-main">
        <CareersSection
          className="bg-blue-bright"
          anchor="open-positions"
          data={openPositionsSectionData}
          dotColor="red"
          style={{ overflow: " visible " }}
        >
          <PositionList />
        </CareersSection>
      </div>
      <OfficeSection data={officeSectionData} />
      <BenefitsSection data={benefitsSectionData} />
      <TeamSection data={teamSectionData} />
      <ContactSection data={contactSectionData} />
    </MainLayout>
  )
}

export default CareersPage
